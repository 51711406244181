import React from 'react';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';
import { Link } from 'react-router-dom';
import './Error.Module.css';
import { Button } from 'react-bootstrap';

function Error() {
	return (
		<>
			<header className='headerError'>
				<LoginNavBar />
			</header>
			<main className='containerError '>
				<h2>Ups, página no encontrada!</h2>
				<Button
					type='submit'
					className='btn btn-secondary py-3 alInicio'
					onClick={() => window.location.replace('/')}
				>
					Volver al inicio
				</Button>
			</main>
		</>
	);
}

export default Error;
