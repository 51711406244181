import React from 'react';
import toast from 'react-hot-toast';
import './NoticiaDetail.Module.css';
import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import notImage from "../../assets/imagenes/notImage.jpg"
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ViewHeadline } from '@mui/icons-material';

function NoticiaDetail() {
  const token = localStorage.getItem('Token');
  const authenticated = isAuthenticated();
  const [noticias, setNoticias] = React.useState([]);
  const [newNoticiaData, setNewNoticiaData] = React.useState({
    titulo: "",
    descripcion: "",
    imageUrl: ""
  });

    const navigate = useNavigate(); // Instancia useNavigate
  const { id } = useParams();
  const [selectedNoticia, setSelectedNoticia] = React.useState(null);
  const [showEditNew, setShowEditNew] = React.useState(false);
  const [showCreateNew, setShowCreateNew] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [imageChangeKey, setImageChangeKey] = React.useState(0);
  const [reloadData, setReloadData] = React.useState(false);
  const config = {
    headers: {
      xtoken: `${token}`,
    },
  };

  React.useEffect(() => {
    setLoader(true)
    const cargarNoticias = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/noticias/${id}`);
      setNoticias(data);
      data&&setLoader(false)
    };
    cargarNoticias();
  }, [reloadData]);

  const [loader, setLoader] = React.useState(true);

  const [image, setImage] = React.useState('');
  const [renderKey, setRenderKey] = React.useState(0);
  const isSuperAdmin = isAuthenticated();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);

  const handleShowEditOpen = (newData) => {
    setSelectedNoticia(newData);
    setShowEditNew(true);
  };

  const handleShowEditClose = () => {
    setSelectedNoticia(null);
    setShowEditNew(false);
  };

  const handleShowCreateOpen = () => {
    setShowCreateNew(true);
  };

  const handleShowCreateClose = () => {
    setNewNoticiaData({
      titulo: "",
      subtitulo:"",
      descripcion: "",
      imageUrl: ""
    });
    setShowCreateNew(false);
  };

  const handleImageChange = async () => {
    const widgetOptions = {
      cloudName: 'dep9gnrhb',
      uploadPreset: 'ml_default',
      cropping: false,
      sources: ['local', 'url', 'camera'],
      multiple: false,
      resourceType: 'image',
      clientAllowedFormats: ['jpg', 'png', 'jpeg'],
      maxFileSize: 5000000,
    };

    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url;

              if (selectedNoticia) {
                // Modificar el campo imageUrl
                const updatedNoticia = { ...selectedNoticia, imageUrl: url };
    
                // Realizar el PUT con el objeto actualizado
                await axios.put(
                  `${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`,
                  updatedNoticia,
                  config
                );
                setSelectedNoticia((prevData) => ({ ...prevData, imageUrl: url }));
                setImageChangeKey((prevKey) => prevKey + 1);
              } else {
                setNewNoticiaData((prevData) => ({ ...prevData, imageUrl: url }));
              }
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );

        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedNoticia((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNewInputChange = (event) => {
    const { name, value } = event.target;
    setNewNoticiaData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateNoticia = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`,
        selectedNoticia,
        config
      );
      setReloadData(!reloadData)
      handleShowEditClose();
      toast.success('Noticia actualizada con éxito');
    } catch (error) {
      console.error('Error al actualizar la noticia:', error);
      toast.error('Error al actualizar la noticia');
    }
  };

  const handleCreateNoticia = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/noticias`,
        newNoticiaData,
        config
      );
     setReloadData(!reloadData)
      handleShowCreateClose();
      toast.success('Noticia creada con éxito');
    } catch (error) {
      console.error('Error al crear la noticia:', error);
      toast.error('Error al crear la noticia');
    }
  };

  const handleDeleteNoticia = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`, config);
      setReloadData(!reloadData);
      handleShowDeleteClose();
      toast.success('Noticia eliminada con éxito');
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
      toast.error('Error al eliminar la noticia');
    }
  };

  const handleShowDeleteOpen = (newData) => {
    setSelectedNoticia(newData);
    setShowDeleteConfirm(true);
  };

  const handleShowDeleteClose = () => {
    setSelectedNoticia(null);
    setShowDeleteConfirm(false);
  };

  return (
    <>
    {loader ? (
      <Loader />
    ) : (
    <Box style={{ 
                width: "100vw", 
                display: "flex", 
                justifyContent: "center", 
                flexDirection: "column", 
                maxHwight:"100vh",
                alignItems: "flex-start", 
                backgroundColor: "white" 
                }}>
      <Box style={{ 
                  display: "flex", 
                  justifyContent: "center", 
                  flexDirection: "column", 
                  alignItems: "flex-start",
                  width: '100%'
                  }}
                  >
            <Typography 
                    sx={{ 
                        fontFamily: "Josefin", 
                        gap: '5px', 
                        display: 'flex', 
                        fontSize: isMobile ? "18px" : '25px', 
                        fontWeight: '400', 
                        alignItems: 'center', 
                        color: '#0077B6', 
                        marginBottom: "20px", 
                        paddingLeft: isMobile && "10px" ,
                        cursor:"pointer",
                        padding: "20px 11%",
                        width: '100%',
                        '&:hover':{

                          textDecoration: 'underline'

                        }
                        }}
                        onClick={() => navigate(-1)}
                        >
                  ← Volver
                </Typography>

      </Box>

      <Box style={{ width: "100%",maxWidth:"100vw", display: "flex", flexDirection: 'column', gap: isMobile ? "20px" : "45px", justifyContent: !isMobile ? "center" : "space-around", }}>

<Box  sx={{borderRadius: "20px", display: 'flex', flexDirection: 'column', gap: '5px'}}>
   

        {noticias?.titulo && ( // Verifica que `noticias.titulo` exista antes de renderizar
          <Box>
            <img
              style={{
                width: '100vw',
                height: isMobile ? "250px" : "750px",
                objectFit: "cover",
              }}
              src={noticias.imageUrl} // Usa directamente `noticias.imageUrl`
              alt={`img de ${noticias.titulo}`} // Usa directamente `noticias.titulo`
            />
          
          </Box>
        )}
     <Typography
          sx={{
            fontFamily: "IvyOra",
            gap: '5px',
            display: 'flex',
            fontSize: isMobile ? "24px" : '36px',
            fontWeight: '400',
            textAlign: "left",
            alignItems: 'center',
            color: '#5C5C5C',
       
            justifyContent: 'space-between',
            alignItems: 'center',
          paddingLeft:"35px",
            paddingRight:"35px"
          }}
        >
          {noticias.titulo}
          <span style={{gap: '5px'}}>
            {authenticated && (
              <>
                <EditIcon
                  sx={{ cursor: "pointer", fill: "#5C5C5C" }}
                  onClick={() => handleShowEditOpen(noticias)}
                />
                <DeleteIcon
                  sx={{
                    cursor: "pointer",
                    fill: "#5C5C5C",
                  }}
                  onClick={() => handleShowDeleteOpen(noticias)}
                />
              </>
            )}
          </span>
        </Typography>
        <Typography
												sx={{
													fontFamily: "IvyOra",
													gap: "5px",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontSize: isMobile ? "18px" : "24px",
                          fontWeight:400,
													textAlign: "left",
													alignItems: "left",
													color: "#5C5C5C",
                          paddingLeft:"35px",
                          paddingRight:"35px",
													marginBottom: "30px",
												}}
												>
												{noticias?.subtitulo
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <br key={idx} />;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
        <Typography
												sx={{
													fontFamily: "IvyOra",
													gap: "5px",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontSize: isMobile ? "16px" : "20px",
													fontWeight: "light",
													textAlign: "left",
													alignItems: "left",
													color: "#5C5C5C",
                        paddingLeft:"35px",
            paddingRight:"35px",
													marginBottom: "30px",
												}}
												>
												{noticias?.descripcion
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <br key={idx} />;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
      </Box>
      </Box>

      <Dialog open={showEditNew} onClose={handleShowEditClose}>
        <DialogTitle>Editar Noticia</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
                    src={selectedNoticia?.imageUrl}
                    alt={`Imagen de ${selectedNoticia?.titulo}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(image)}
                  >
                    <EditIcon />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  name="titulo"
                  fullWidth
                  value={selectedNoticia?.titulo || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                 fullWidth
                 multiline
                 rows={4}
                  label="Subtítulo"
                  name="subtitulo"
                 
                  value={selectedNoticia?.subtitulo || ''}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>Para hacer saltos de linea escribir <strong>\n</strong>  donde se necesite el salto.</Typography>
              
              </Grid>
              <Grid item xs={12}>
                <Typography>Para colocar texto en negrita, colocar * al comienzo y al final del texto que quiera resaltarse. Ej: *hola* se verá  <strong>hola</strong> </Typography>
              
              </Grid>
              <Grid item xs={12}>
              <Typography>Para colocar texto en cursiva, colocar # al comienzo y al final del texto que se solicite. Ej: #hola# se verá  <em>hola</em></Typography>
              
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  name="descripcion"
                  fullWidth
                  multiline
                  rows={4}
                  value={selectedNoticia?.descripcion || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleUpdateNoticia}>
                  Actualizar Noticia
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>



      <Dialog open={showDeleteConfirm} onClose={handleShowDeleteClose}>
        <DialogTitle>Eliminar Noticia</DialogTitle>
        <DialogContent>
          <Typography>¿Seguro que deseas eliminar esta noticia?</Typography>

          <Box sx={{display:"flex", justifyContent:"space-around"}}>
          <Box sx={{
            backgroundColor:"#c00000",
            height:"42px",
            width:"120px",
            borderRadius:"5px",
            display:"flex",
            cursor:"pointer",
            justifyContent:"center",
            alignItems:"center"
          }} onClick={handleDeleteNoticia}>
          <Typography sx={{
            color:"#5C5C5C",
          fontFamily:"Josefin"
          }}>
            Aceptar
            </Typography>
          </Box>
          <Button onClick={handleShowDeleteClose}>
            Cancelar
          </Button>

          </Box>
        </DialogContent>
      </Dialog>
    </Box>
    	)
      }
    </>
  );
}

export default NoticiaDetail;
