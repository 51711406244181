import axios from 'axios';
import jwtDecode from 'jwt-decode';

import toast from 'react-hot-toast';

const uri = process.env.REACT_APP_BACK_URL;
const token = localStorage.getItem('Token');

export const getUser = async (id) => {
	const { data } = await axios.get(`${uri}/api/usuarios/${id}`);
	return data;
};

export const loginFunction = async (user) => {
	try {
		const { data } = await axios.post(`${uri}/api/auth/login`, user);

		localStorage.setItem('Usuario', data.usuario._id);
		localStorage.setItem('Token', data.token);

		toast.success(`Bienvenido ${data.usuario?.name}`, { duration: 3000 });
	} catch (error) {
		toast.error(`${error.response.data.msg}`, { duration: 3000 });
	}
};

export function isAuthenticated() {
	const token = localStorage.getItem('Token');
	if (!token) return false;
	if (validateExpDate(token)) return true;
}
function validateExpDate(token) {
	const decoded = jwtDecode(token);
	if (!decoded) return false;
	if (decoded.exp * 1000 < Date.now()) {
		console.log('Debes iniciar sesión nuevamente!');
		localStorage.clear();
		return false;
	}

	return true;
}

export function logout() {
	localStorage.removeItem('Token');
	localStorage.removeItem('Usuario');

	return true;
}
