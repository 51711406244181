import axios from 'axios';

const uri = process.env.REACT_APP_BACK_URL;
export const getProductos = async () => {
	const { data } = await axios.get(`${uri}/api/productos`);
	return data;
};

export const getDatos = async () => {
	const { data } = await axios.get(`${uri}/api/datoscontacto`);
	return data[0];
};

export const getProductById = async (id) => {
	const { data } = await axios.get(`${uri}/api/productos/${id}`);
	return data;
};
