import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import LoginNavBar from '../../components/loginNavBar/LoginNavBar';

import { loginFunction, isAuthenticated } from '../../utils/usuario/usuario';

import { validateLogin } from '../../utils/validate/validate';
import Loader from '../../components/loader/Loader';

//import Loader from '../../components/loader/Loader';
import './Login.Module.css';
import { Button } from 'react-bootstrap';

function Login() {
	const [loader, setLoader] = React.useState(true);

	const navigate = useNavigate();
	const [errors, setErrors] = React.useState({});

	const [userInfo, setUserInfo] = React.useState({
		name: '',
		password: '',
	});

	const [openEye, setOpenEye] = React.useState(true);
	const toggleEye = () => {
		setOpenEye(!openEye);
	};

	const handleChange = (e) => {
		setUserInfo({
			...userInfo,
			[e.target.name]: e.target.value,
		});
		setErrors(
			validateLogin({
				...userInfo,
				[e.target.name]: e.target.value,
			})
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (errors.correo || errors.password) {
			toast.error('Revisa tus credenciales');
		} else {
			try {
				await loginFunction(userInfo);

				if (isAuthenticated()) {
					setTimeout(() => {
						navigate('/');
					}, 1500);
				}
			} catch (error) {
				toast.error(`${error.response.data.msg}`, { duration: 3000 });
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 500);
	}, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<>
					<header>
						<LoginNavBar />
					</header>
					<main className='mainLogin'>
						<Button className='toHome' onClick={() => navigate('/')}>
							volver a home
						</Button>

						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>
						<div className='container containerForm p-5'>
							<h2>Iniciar Sesión</h2>
							<h4 className='pb-3'>Por favor, ingresá tu usuario y contraseña</h4>
							<form action='' onSubmit={handleSubmit}>
								<div className=' px-3 py-1'>
									{/* <label className='form-label' for='first_name'>
								Correo electrónico:
							</label> */}
									<input
										onChange={handleChange}
										className='form-control shadow py-3'
										name='name'
										type='text'
										placeholder='Usuario'
									></input>
									{errors.name ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.name}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className=' px-3 py-1 contraseña  '>
									<div className='input-group'>
										<input
											autoComplete='off'
											onChange={handleChange}
											className='form-control shadow py-3'
											name='password'
											type={openEye ? 'password' : 'text'}
											placeholder='Contraseña'
										/>
										<div className='input-group-apend'>
											<span className='input-group-text shadow py-3'>
												{openEye ? (
													<abbr title='Mostrar contraseña'>
														<i onClick={toggleEye} className='bi bi-eye-slash'></i>
													</abbr>
												) : (
													<abbr title='Ocultar contraseña'>
														<i onClick={toggleEye} className='bi bi-eye'></i>
													</abbr>
												)}
											</span>
										</div>
									</div>
									{errors.password ? (
										<p
											className='pt-1'
											style={{ color: 'rgba(255, 0, 0, 0.719)', height: '20px', textAlign: 'left' }}
										>
											{errors.password}
										</p>
									) : (
										<br></br>
									)}
								</div>
								<div className='botonIngresar px-5 py-3 mt-5'>
									<Button
										type='submit'
										className='btn btn-secondary py-3'
										disabled={!userInfo.name && !userInfo.password}
									>
										INGRESAR
									</Button>
								</div>
							</form>
						</div>
					</main>
				</>
			)}
			;
		</>
	);
}

export default Login;
