import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider } from 'react-router-dom';
//import reportWebVitals from './reportWebVitals';
import router from './Router';

import IvyOraFont from "./fonts/IvyOra.ttf"

import LastFont from "./fonts/Last.otf"

import AngelFont from "./fonts/Angel.ttf"

import VeryFont from "./fonts/VeryPunkt.otf"

import JosefinSansFont from "./fonts/JosefinSans.ttf"


const fontStyles = `
  @font-face {
    font-family: 'IvyOra';
    src: url(${IvyOraFont}) format('truetype');
  }

  @font-face {
    font-family: 'Angel';
    src: url(${AngelFont}) format('truetype');
  }

  @font-face {
    font-family: 'Josefin';
    src: url(${JosefinSansFont}) format('truetype');
  }

@font-face {
  font-family: 'Last';
  src: url('./fonts/Last.otf') format('opentype');
}
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = fontStyles;
document.head.appendChild(styleTag);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<RouterProvider router={router}>
			<App />
		</RouterProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
