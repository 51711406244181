import React from 'react';
import toast from 'react-hot-toast';
import './Noticias.Module.css';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

import Loader from '../../components/loader/Loader';

import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';

import notImage from "../../assets/imagenes/notImage.jpg"
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

function Noticias() {
  const token = localStorage.getItem('Token');
  const authenticated = isAuthenticated();
  const navigate = useNavigate(); // Instancia useNavigate
  
  const [noticias, setNoticias] = React.useState([]);
  const [newNoticiaData, setNewNoticiaData] = React.useState({
    titulo: "",
    subtitulo:"",
    descripcion: "",
    imageUrl: ""
  });
  const [selectedNoticia, setSelectedNoticia] = React.useState(null);
  const [showEditNew, setShowEditNew] = React.useState(false);
  const [showCreateNew, setShowCreateNew] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [imageChangeKey, setImageChangeKey] = React.useState(0);
  const [reloadData, setReloadData] = React.useState(false);
  const config = {
    headers: {
      xtoken: `${token}`,
    },
  };

  React.useEffect(() => {
    setLoader(true)
    const cargarNoticias = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/noticias`);

      setNoticias(data);
      data && setLoader(false)
    };
    cargarNoticias();
  }, [reloadData]);

  const [loader, setLoader] = React.useState(true);

  const [image, setImage] = React.useState('');
  const [renderKey, setRenderKey] = React.useState(0);
  const isSuperAdmin = isAuthenticated();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);

  const handleShowEditOpen = (newData) => {
    setSelectedNoticia(newData);
    setShowEditNew(true);
  };

  const handleShowEditClose = () => {
    setSelectedNoticia(null);
    setShowEditNew(false);
  };

  const handleShowCreateOpen = () => {
    setShowCreateNew(true);
  };

  const handleShowCreateClose = () => {
    setNewNoticiaData({
      titulo: "",
      descripcion: "",
      imageUrl: ""
    });
    setShowCreateNew(false);
  };

  const handleImageChange = async () => {
    const widgetOptions = {
      cloudName: 'dep9gnrhb',
      uploadPreset: 'ml_default',
      cropping: false,
      sources: ['local', 'url', 'camera'],
      multiple: false,
      resourceType: 'image',
      clientAllowedFormats: ['jpg', 'png', 'jpeg'],
      maxFileSize: 5000000,
    };

    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url;

              if (selectedNoticia) {
                // Modificar el campo imageUrl
                const updatedNoticia = { ...selectedNoticia, imageUrl: url };
    
                // Realizar el PUT con el objeto actualizado
                await axios.put(
                  `${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`,
                  updatedNoticia,
                  config
                );
                setSelectedNoticia((prevData) => ({ ...prevData, imageUrl: url }));
                setImageChangeKey((prevKey) => prevKey + 1);
              } else {
                setNewNoticiaData((prevData) => ({ ...prevData, imageUrl: url }));
              }
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );

        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedNoticia((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNewInputChange = (event) => {
    const { name, value } = event.target;
    setNewNoticiaData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateNoticia = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`,
        selectedNoticia,
        config
      );
      setReloadData(!reloadData)
      handleShowEditClose();
      toast.success('Noticia actualizada con éxito');
    } catch (error) {
      console.error('Error al actualizar la noticia:', error);
      toast.error('Error al actualizar la noticia');
    }
  };

  const handleCreateNoticia = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/noticias`,
        newNoticiaData,
        config
      );
     setReloadData(!reloadData)
      handleShowCreateClose();
      toast.success('Noticia creada con éxito');
    } catch (error) {
      console.error('Error al crear la noticia:', error);
      toast.error('Error al crear la noticia');
    }
  };

  const handleDeleteNoticia = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/noticias/${selectedNoticia._id}`, config);
      setReloadData(!reloadData);
      handleShowDeleteClose();
      toast.success('Noticia eliminada con éxito');
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
      toast.error('Error al eliminar la noticia');
    }
  };

  const handleShowDeleteOpen = (newData) => {
    setSelectedNoticia(newData);
    setShowDeleteConfirm(true);
  };

  const handleShowDeleteClose = () => {
    setSelectedNoticia(null);
    setShowDeleteConfirm(false);
  };

  return (
    <>
    {loader ? (
      <Loader />
    ) : (
    <Box style={{ 
                  maxWidth: "100vw", 
                  display: "flex", 
                  justifyContent: "center", 
                  flexDirection: "column", 
                  alignItems: !isMobile ? "center" : "flex-start", 

                  paddingBottom: "5vh", 
                  backgroundColor: "white" 
                }}>

    <Box className="navBlancaNews" sx={{padding:"5vw",width:"100vw", display:"flex", flexDirection: isMobile ? 'column' : 'row', justifyContent:"space-between", gap: isMobile && '30px', alignItems: isMobile ? "center" : "flex-end"}}>
        <img   onClick={() => navigate(`/`)}src="https://i.imgur.com/5RfptjD.png" alt="LogoClinicaBergesi" style={{cursor:"pointer",width: isMobile ? '75%' : '400px'}}/>

        {authenticated && (
        <Button onClick={handleShowCreateOpen} variant="contained" color="primary">
          Crear Noticia
        </Button>
        )}

      </Box>

      <Box style={{ 
        width: "100%",
        maxWidth:"100vw",
        flexWrap:"wrap", 
        display: "flex", 
        gap: isMobile ? "20px" : "45px", 
        justifyContent: !isMobile ? "center" : "space-around", 
      }}>

    <Typography className="procedureTitle" sx={{color: '#5C5C5C'}}> Últimas noticias </Typography>

     {noticias?.noticias?.map((v, index) => (
    <Box
      key={index}
      sx={{
        width: isMobile ? '90%' : "450px",
        height: "630px",
        border: "1px solid #073EB8",
        position: "relative",
        backgroundColor: "white",
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        gap: "30px"
        // No clickeable directamente
      }}
    >
      <img
        style={{
          width: "100%",
          height: "350px",
          objectFit: "cover",
          borderRadius: "0px",
        }}
        src={v.imageUrl}
        alt={`img de ${v.titulo}`}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px"
        }}
      >
        <Typography
          sx={{
            
            fontFamily: "IvyOra",
            gap: "5px",
            display: "flex",
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: '100%',
            fontWeight: "400",
            textAlign: "left",
            alignItems: "center",
            color: "#5C5C5C",
            marginBottom: "15px",
            paddingLeft: "15px",

          }}
        >
          {v.titulo}
            {authenticated && (
            <>
              <EditIcon
                sx={{ cursor: "pointer", fill: "#5c5c5c" }}
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el click en el ícono propague al contenedor
                  handleShowEditOpen(v);
                }}
              />
              <DeleteIcon
                sx={{ cursor: "pointer", fill: "#5c5c5c", marginLeft: "10px" }}
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el click en el ícono propague al contenedor
                  handleShowDeleteOpen(v);
                }}
              />
            </>
          )}
        </Typography>
        
        {/* <Typography
          sx={{
            paddingLeft: "15px",
            fontFamily: "Nunito",
            gap: "5px",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: isMobile ? "12px" : "19px",
            fontWeight: "400",
            textAlign: "left",
            alignItems: "center",
            color: "#fff",
            marginBottom: "30px",

          }}
        >
          {v?.descripcion}
        </Typography> */}
            <Typography
              sx={{
                paddingLeft: "15px",
                fontFamily: "Josefin",
                gap: "5px",
                display: "-webkit-box",
                WebkitLineClamp: 3, // Límite de 4 líneas
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: isMobile ? "24px" : "28px",
                fontWeight: "light",
                textAlign: "left",
                alignItems: "left",
                color: "#5C5C5C",
                marginBottom: "30px",
                lineHeight: "100%"
              }}
            >
              {v?.subtitulo
                ?.split("\\n") // Divide en párrafos.
                .slice(0, 3) // Toma solo las primeras 4 líneas.
                .join(" ") // Une las líneas seleccionadas en una sola cadena.
                .split(/(\*[^*]*\*|#[^#]*#)/) // Aplica formato.
                .map((text, idx) => {
                  if (text.startsWith("*") && text.endsWith("*")) {
                    // Texto en negrita.
                    return (
                      <strong className="strNew" key={idx}>
                        {text.slice(1, -1)}
                      </strong>
                    );
                  } else if (text.startsWith("#") && text.endsWith("#")) {
                    // Texto en cursiva.
                    return (
                      <em className="strNew" key={idx}>
                        {text.slice(1, -1)}
                      </em>
                    );
                  } else {
                    return text;
                  }
                })}
            </Typography>

        
        {/* Botón Ver Más */}
        <Box
          sx={{
        
            position: "absolute",
            bottom: "10px",
            left: "10px",
            color: "#5C5C5C",
            fontSize: "24px",
            cursor: "pointer",
            fontFamily: "Josefin",
            fontWeight: "light",
            '&:hover':{

              textDecoration: 'underline',
              color: '#215AA6'

            }
          }}
          onClick={() => navigate(`/noticias/${v._id}`)}
        >
          Leer más ⨁
        </Box>
      </Box>
    </Box>
  ))}
      </Box>

      <Dialog open={showEditNew} onClose={handleShowEditClose}>
        <DialogTitle>Editar Noticia</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
                    src={selectedNoticia?.imageUrl}
                    alt={`Imagen de ${selectedNoticia?.titulo}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(image)}
                  >
                    <EditIcon />
                  </div>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  name="titulo"
                  fullWidth
                  value={selectedNoticia?.titulo || ''}
                  onChange={handleInputChange}
                />
              </Grid>
                <Grid item xs={12}>
                <TextField
                multiline
                rows={4}
                  label="Subtítulo"
                  name="subtitulo"
                  fullWidth
                  value={selectedNoticia?.subtitulo || ''}
                  onChange={handleInputChange}
                />
              </Grid>
                 <Grid item xs={12}>
                              <Typography>Para hacer saltos de linea escribir <strong>\n</strong>  donde se necesite el salto.</Typography>
                            
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Para colocar texto en negrita, colocar * al comienzo y al final del texto que quiera resaltarse. Ej: *hola* se verá  <strong>hola</strong> </Typography>
                            
                            </Grid>
                            <Grid item xs={12}>
                            <Typography>Para colocar texto en cursiva, colocar # al comienzo y al final del texto que se solicite. Ej: #hola# se verá  <em>hola</em></Typography>
                            
                            </Grid>
                            
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  name="descripcion"
                  fullWidth
                  multiline
                  rows={4}
                  value={selectedNoticia?.descripcion || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleUpdateNoticia}>
                  Actualizar Noticia
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={showCreateNew} onClose={handleShowCreateClose}>
        <DialogTitle>Crear Nueva Noticia</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Box sx={{ position: "relative", width: "150px", height: "150px" }}>
                  <img
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
                    src={newNoticiaData.imageUrl || notImage}
                    alt="Nueva Imagen"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer"
                    }}
                    onClick={() => handleImageChange(null)}
                  >
                    <EditIcon />
                  </div>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  name="titulo"
                  fullWidth
                  value={newNoticiaData.titulo}
                  onChange={handleNewInputChange}
                />
              </Grid>

                 <Grid item xs={12}>
                              <Typography>Para hacer saltos de linea escribir <strong>\n</strong>  donde se necesite el salto.</Typography>
                            
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Para colocar texto en negrita, colocar * al comienzo y al final del texto que quiera resaltarse. Ej: *hola* se verá  <strong>hola</strong> </Typography>
                            
                            </Grid>
                            <Grid item xs={12}>
                            <Typography>Para colocar texto en cursiva, colocar # al comienzo y al final del texto que se solicite. Ej: #hola# se verá  <em>hola</em></Typography>
                            
                            </Grid>
                            
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  name="descripcion"
                  fullWidth
                  multiline
                  rows={4}
                  value={newNoticiaData.descripcion}
                  onChange={handleNewInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleCreateNoticia}>
                  Crear Noticia
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteConfirm} onClose={handleShowDeleteClose}>
        <DialogTitle>Eliminar Noticia</DialogTitle>
        <DialogContent>
          <Typography>¿Seguro que deseas eliminar esta noticia?</Typography>

          <Box sx={{display:"flex", justifyContent:"space-around"}}>
          <Box sx={{
            backgroundColor:"#c00000",
            height:"42px",
            width:"120px",
            borderRadius:"5px",
            display:"flex",
            cursor:"pointer",
            justifyContent:"center",
            alignItems:"center"
          }} onClick={handleDeleteNoticia}>
          <Typography sx={{
            color:"#fff",
          fontFamily:"Nunito"
          }}>
            Aceptar
            </Typography>
          </Box>
          <Button onClick={handleShowDeleteClose}>
            Cancelar
          </Button>

          </Box>
        </DialogContent>
      </Dialog>
    </Box>
    			)
        }
    </>
  );
}

export default Noticias;
