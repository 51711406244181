import React from 'react';
import Formulario1 from '../../components/formulario1/Formulario1';
import Formulario2 from '../../components/formulario2/Formulario2';
import Footer from '../../components/footer/Footer';
import Porque from '../../components/porque/Porque.jsx';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FlippingCard from '../../components/flippingCard/FlippingCard.jsx';
import CloseIcon from "@mui/icons-material/Close";
import Fade from '@mui/material/Fade';



import {
  Avatar,
  Box,
  Badge,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
	Stack,
	TextField,
  Grid,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
//import NavBar from '../../components/navBar/NavBar';


import Preguntas from '../../components/Preguntas/Preguntas.jsx';
import Ventajas from '../../components/ventajas/Ventajas.jsx';
import Testimonios from '../../components/testimonios/Testimonios.jsx';
import Video from "../../assets/videos/video3.mp4"
import {useNavigate} from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import Stamp from '../../components/stamp/Stamp';
import scroll from '../../assets/imagenes/scroll-bar.png';

import { getProductos, getDatos } from '../../utils/productos/productos';
import './Home.Module.css';
import CarouselImg from '../../components/carousel/Carousel.jsx';

import { isAuthenticated } from '../../utils/usuario/usuario';
import { Toaster, toast } from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import axios from 'axios';

import ReactMarkdown from 'react-markdown';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

import FlippingCard2 from '../../components/flippingCard2/FlippingCard2.jsx';

function Home() {

	const procedimientos=[
		{
			nombre:"Otoplastia",
			descripcion:"Armoniza la forma y posición de nuestras orejas con el resto del rostro. \n Es un procedimiento delicado que moldea y reposiciona las orejas prominentes."
		},
		{
			nombre:"Queiloplastia",
			descripcion:"Redefine y rejuvenece el contorno labial, manteniendo la armonía y expresión natural del rostro, sin alterar la esencia de nuestra sonrisa."

		},{
			nombre:"Lifting Cérvico-Facial",
			descripcion:"Revitaliza  nuestro rostro y cuello, tensando suavemente la piel y los tejidos subyacentes. \n Este procedimiento resalta la estructura facial, eliminando los signos del efecto de la gravedad mientras conserva la expresión natural y la vitalidad de cada individuo."

		},{
			nombre:"Bichectomía",
			descripcion:"Es una técnica quirúrgica que reduce el volumen de las mejillas para esculpir y definir los contornos faciales. \n  Se destacan los pómulos, aportando una apariencia más estilizada sin perder la expresión natural del rostro."

		},{
			nombre:"Ginecomastia",
			descripcion:"Procedimiento quirúrgico que aborda el desarrollo excesivo de tejido mamario en hombres, restaurando un contorno torácico más firme y masculino. \n La masculinización del tórax mejora la confianza y la estética corporal sin comprometer la naturalidad del resultado."
		},

		{
			nombre:"Mastoplastia",
			descripcion:"De aumento: Armoniza la figura femenina mediante la inserción de implantes de silicona homologados de alta tecnología. \n  Estos proporcionan volumen y forma al busto, respetando la proporción y estética natural de cada cuerpo. \n  Tanto el tamaño como su morfología son elegidos por la paciente mediante pruebas. \n Reducción: Alivia las molestias causadas por un busto voluminoso, equilibrando la figura y mejorando el confort físico y emocional. \n Levantamiento: Restaura el busto que descendió por efecto de la gravedad devolviéndolo a su posición y firmeza, sin alterar la forma natural. \n Reparadora: corrige asimetrías y deformidades del busto, devolviendo armonía y confianza a la persona, preservando siempre su identidad física."
		}
		,{
			nombre:"Abdominoplastia de Abdomen",
			descripcion:"Redefine el contorno abdominal al eliminar piel, grasa, y tensar los músculos subyacentes. \n  Este procedimiento crea un perfil más liso y firme, resaltando la silueta natural y mejorando la autopercepción."
		}
		,{
			nombre:"Lipoaspiración",
			descripcion:"Esculpe y moldea el cuerpo mediante la eliminación de depósitos de grasa localizada. \n Esta técnica delicada acentúa las líneas naturales del cuerpo, proporcionando una figura más estilizada sin perder la armonía corporal."
		},
		{
			nombre:"Dermolipectomía de Muslos y Glúteos",
			descripcion:"Elimina el exceso de piel y grasa de los muslos y glúteos, restaurando una apariencia firme y tonificada. \n  Este procedimiento define la silueta corporal, manteniendo la naturalidad de las formas."
		},
		{
			nombre:"Lipoaspiración de Muslos",
			descripcion:"Esculpe y moldea el cuerpo mediante la eliminación de depósitos de grasa localizada. \n Esta técnica delicada acentúa las líneas naturales del cuerpo, proporcionando una figura más estilizada sin perder la armonía corporal."
		},
		{
			nombre:"Relleno de Glúteos (Lipofilling)",
			descripcion:"Utiliza la propia grasa del paciente para aumentar y esculpir los glúteos, creando una forma más voluptuosa y natural."
		},
		{
			nombre:"Rellenos",
			descripcion:"Los rellenos dérmicos son la solución ideal para líneas, arrugas, pliegues, surcos y flacidez. \n Utilizamos solo materiales de alta tecnología  y de seguridad comprobada que renueven la turgencia de la piel y la vitalidad de las zonas tratadas."
		},
		{
			nombre:"Toxina Botulínica",
			descripcion:"La toxina botulínica relaja los músculos faciales, reduciendo líneas de expresión y creando un semblante fresco y descansado."
		},
		{
			nombre:"Mesoterapia",
			descripcion:"La mesoterapia revitaliza la piel mediante microinyecciones de vitaminas y nutrientes, mejorando su apariencia y luminosidad."
		},
		{
			nombre:"Hilos",
			descripcion:"Los hilos tensores proporcionan un efecto lifting no quirúrgico, tensando y rejuveneciendo la piel de manera natural y duradera."
		}
	]

	const clinica=[
		{
			nombre:"3",
			descripcion:"Es una institución con veinte años de trayectoria en investigación, docencia y práctica quirúrgica que nos permite asegurar intervenciones de alto conocimiento técnico y actualización cientifica. \n El Dr. Gustavo Bergesi se encuentra acompañado de un gran equipo quirúrgico compuesto por Cirujanos ayudantes, anestesistas, enfermeras e instrumentadores quirurgicos, todos profesionales debidamente certificados."
		},
		{
			nombre:"1",
			descripcion:"La seguridad es nuestra *prioridad*, por eso solo intervenimos en quirófano propio que cumple con todas las normativas y regulaciones médicas de las más altas instituciones nacionales. \n Además, desde el inicio hasta el final, la atención está brindada por personal médico, y toda intervención que requiera de anestesia está supervisada por un médico anestesiólogo."
		},
		{
			nombre:"2",
			descripcion:"Luego de la cirugía nuestros pacientes disfrutan de una recuperación rápida y cuidada en habitaciones privadas, con atención exclusiva de médicos y enfermeros profesionales. \n Nuestras instalaciones están diseñadas para darte una experiencia relajante, de disfrute y privacidad."
		},
	]
		
	const biografia= "*Gustavo Bergesi* nació en el seno de una familia numerosa de origen italiano en la ciudad de Córdoba. \n Su curiosidad lo acercó a la música y la pintura. \n A sus 18 años, gracias a una beca otorgada por el *Dr. Ramón Carrillo* se recibió de enfermero profesional en La escuela Cecilia Grierson de la Ciudad de Buenos Aires. \n Pero el servicio militar obligatorio lo trasladó a tucuman. \n Allí ejerció la profesión de enfermero en los servicios de cirugía general mientras cursaba la carrera de medicina.\n Su vocación y espíritu de servicio eran claros, tanto que a sus *24 años* se convirtió en médico. \n "

	const biografia2= "Luego se trasladó al hospital de Cachi, Salta, en donde trabajó como médico cirujano y ocupó, con el tiempo, el cargo de director. \n Su amor por aquel pueblo fue creciendo y hoy continúa siendo su lugar en el mundo. \n Ejerció la especialidad en el servicio de cirugía plástica y quemados en el hospital San Bernardo, Salta. \n Sus primeros pasos en la plastica los caminó en *Los Ángeles California* bajo la mentoría el *Doctor Nicanor Isse (UCLA)*. \n Continuó su preparación en la cirugía plástica en Roma y Madrid junto al *Dr. Roy Camacho*. \n También para ese entonces  de la práctica del maestro salteño *Dr. Juan Portal*."

	const biografia3= "Para coronar, transitó la residencia completa en cirugía plástica y estética en Buenos Aires con el maestro *José Juri* en su clínica; \n y posteriormente cursó la carrera superior de Cirugía Plástica, estética y quemados en la Universidad de Buenos Aires de donde es graduado. \n A partir de entonces no dejaría de estudiar y prepararse. \n Como parte de su recorrido se especializó en la Universidad Interamericana de Buenos Aires y en la Universidad Nacional de Tucumán contando con la certificación de especialista de estas dos universidades y del colegio médico de Salta. \n Actualmente es miembro titular de *SACPER* (sociedad argentina de cirugía plástica), Sociedad Salteña de Cirugía Plástica, y Sociedad Salteña de Cirugía Plástica. \n Finalmente en 2005 fundó su clínica en donde se dedica a la asistencia, docencia e investigación. \n Desde entonces ha operado a más de *15.000* pacientes."
	
	const navigate = useNavigate();
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [productos, setProductos] = React.useState([]);
	const [procedimientoSeleccionado, setProcedimientoSeleccionado] = React.useState(null);
	const [showProcedimiento, setShowProcedimiento] = React.useState(false);

	const [clinicaSeleccionada, setClinicaSeleccionada] = React.useState(null);
	const [showClinica, setShowClinica] = React.useState(false);
	const [showBiografia, setShowBiografia] = React.useState(false);

	const [noticias, setNoticias] = React.useState([]);

	const [datosContacto, setDatosContacto] = React.useState([]);
const authenticated = isAuthenticated()


React.useEffect(() => {
	setLoader(true);
	const cargarNoticias = async () => {
	  const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/noticias`);
	  
	  if (data) {
		const ultimasNoticias = data.noticias?.reverse().slice(0, 3);
		setNoticias(ultimasNoticias);
		setLoader(false);
	  }
	};
	cargarNoticias();
  }, []);
// console.log("autenti", authenticated)
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

	const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

	const [showScrollButton, setShowScrollButton] = React.useState(false);


	const [scrollPosition, setScrollPosition] = React.useState(0);
	// Función para manejar el evento de scroll
	const handleScrollBu = () => {
		const currentPosition = window.pageYOffset;
		setScrollPosition(currentPosition);
		setShowScrollButton(currentPosition > 100); // Mostrar la flecha cuando el scroll supere los 100px
	};

React.useEffect(() => {
	window.addEventListener('scroll', handleScrollBu);
	return () => {
		window.removeEventListener('scroll', handleScrollBu);
	};
}, []);

	const [viewScroll, setViewScroll] = React.useState(false);
	const [showEditImages, setShowEditImages] = React.useState(false)

	const [renderKey, setRenderKey] = React.useState(0);
	const handleShowEditOpen =() => {
		setShowEditImages(true)
	}
	const handleShowEditClose =() => {
		setShowEditImages(false)
	}
	const [isWidgetOpened, setWidgetOpened] = React.useState(false);
  const widgetRef = React.useRef(null);
	const [imageChangeKey, setImageChangeKey] = React.useState(0);
	const [loader, setLoader] = React.useState(false);
const [imageTop, setImageTop] = React.useState('');

const handleScroll = (name) => {
	const contactElement = document.querySelector(name);
	if (contactElement) {
		contactElement.scrollIntoView({ behavior: 'smooth' });
		setShowNav(false)
}
}

const [formData, setFormData] = React.useState({
	nombre: '',
	email: '',
	telefono: '',
	mensaje: ''
});
//Estados para imagenes parte superior
const [braImage, setBraImage] = React.useState('');
const [faceImage, setFaceImage] = React.useState('');
const [smalFaceImage, setSmallFaceImage] = React.useState('');
const [showNav, setShowNav] = React.useState(false)

const [isExiting, setIsExiting] = React.useState(false);

const handleShowNav = () => {
	if (showNav) {
		// Inicia la animación de salida
		setIsExiting(true);
		// Espera hasta que la animación de salida termine
		setTimeout(() => {
			setShowNav(false);
			setIsExiting(false); // Resetea el estado de salida
		}, 500); // Duración de la animación (500ms en este caso)
	} else {
		setShowNav(true);
	}
};

	
const handleShowProcedimiento = (newData) => {
	const proced = procedimientos?.find(e => e.nombre === newData);

	setProcedimientoSeleccionado(proced); 
	setShowProcedimiento(true);
};

const handleShowProcedimientoClose = () => {
	setProcedimientoSeleccionado(null);
	setShowProcedimiento(false);
};

const handleShowClinica = (newData) => {
	const proced = clinica?.find(e => e.nombre === newData);
	setClinicaSeleccionada(proced); 
	setShowClinica(true);
};

const handleShowClinicaClose = () => {
	setClinicaSeleccionada(null);
	setShowClinica(false);
};

const handleShowBiografia = (newData) => {
	 
	setShowBiografia(true);
};

const handleShowBiografiaClose = () => {

	setShowBiografia(false);
};


	const [video, setVideo] = React.useState('');
	const [isEditing, setIsEditing] = React.useState(false);
	const [newVideo, setNewVideo] = React.useState({});

	const videoId = video[0]?.url?.split('v=')[1];

	const opts = {
		width: '100%',
		heigth: '100%',
		playerVars: {
			// Opciones del reproductor, como el autoplay
			autoplay: false,
		},
	};

	const handleImageChange = async (image, index) => {
    const widgetOptions = {
      cloudName: 'duomlqsl4', // Reemplaza con el nombre de tu cuenta en Cloudinary
      uploadPreset: 'ml_default', // Reemplaza con el nombre del preset de carga en Cloudinary
      cropping: false, // Deshabilitar la opción de recorte
      sources: ['local', 'url', 'camera'], // Permitir cargar desde archivos locales, URL o cámara
      multiple: false, // Permitir solo una imagen a la vez
      resourceType: 'image', // Permitir solo la carga de imágenes
      clientAllowedFormats: ['jpg', 'png', 'jpeg'], // Formatos permitidos (puedes agregar otros si lo deseas)
      maxFileSize: 15000000, // Tamaño máximo del archivo (en bytes), aquí son 5 MB
    };
  
    try {
      if (widgetRef.current) {
        setWidgetOpened(true);
        widgetRef.current.open();
      } else {
        const widget = window.cloudinary.createUploadWidget(
          widgetOptions,
          async (error, result) => {
            if (!error && result && result.event === 'success' && result.info.secure_url) {
              const url = result.info.secure_url; //se guarda la url nueva de la img que se subio
  
              // Actualizar la imagen en el estado local
              setImageTop((prevImages) =>
                prevImages.map((image, i) => (i === index ? { ...url, url } : image))
              );
  
              // Actualizar la imagen en el backend
              await axios.put(
                `${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
                '',
                config
              );
  
              // Incrementar el estado imageChangeKey para forzar la recarga del componente
              setImageChangeKey((prevKey) => prevKey + 1);
            } else if (error) {
              console.error('Error al cargar la imagen:', error);
            }
          }
        );
  
        widgetRef.current = widget;
        widget.open();
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

	const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Envía los datos al backend
			const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/noticias/formulario`, formData);
      
      if (response.status === 200) {
        toast.success("Formulario enviado con éxito");
      } else {
        toast.error("Hubo un problema al enviar el formulario");
      }
				
 setFormData({
    nombre: '',
    email: '',
    telefono: '',
    mensaje: ''
  });
    } catch (error) {
      toast.error("Error al enviar el formulario:", error);
      alert("Error al enviar el formulario");
    }
  };

	const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

		
	const handleEditVideo = () => {
		setIsEditing(true);
		setNewVideo({
			url: video[0]?.url,
			descripcion: video[0]?.descripcion,
		});
	};

	const handleCancelEditVideo = () => {
		setIsEditing(false);
	};

	const handleSaveVideo = async () => {
		try {
			// Envía la nueva URL y descripción en el cuerpo de la solicitud
			const response = await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes/${video[0]._id}?url=${newVideo.url}`,
				{ descripcion: newVideo.descripcion },
				config
			);

			// Actualiza el estado del video con los datos actualizados
			setVideo([{ url: newVideo.url, descripcion: newVideo.descripcion }]);
			setIsEditing(false);
			toast.success('Video actualizado con éxito!');
		} catch (error) {
			toast.error('Hubo un problema al cambiar el video');
		}
	};

	const handleVideoInputChange = (event) => {
		const { name, value } = event.target;
		setNewVideo({ ...newVideo, [name]: value });
	};

	const GoogleMap = () => {
		return (
		  <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<iframe
			  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.1494811066796!2d-65.42110905999755!3d-24.79033446888068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x941bc3b1eb4da4bb%3A0x19d846ebd59fb0a8!2sAlvarado%201434%2C%20A4400%20Salta!5e0!3m2!1ses!2sar!4v1734488037004!5m2!1ses!2sar"
			  width="400"
			  height="300"
			  style={{ border: 0 }}
			  allowFullScreen=""
			  loading="lazy"
			  referrerPolicy="no-referrer-when-downgrade"
			  title="Google Map Location"
			></iframe>
		  </div>
		);
	  };

// console.log(imageTop)
	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div style={{maxWidth:"100vw", overflow:"hidden", background: 'white'}}>
					<Toaster
					position='bottom-left'
					reverseOrder={false}
					toastOptions={{ className: 'fondoToast' }}
				/>

					{/*  Navbar Superior  */}
					{
						isMobile && (
							<Box style={{position:"absolute", display:"flex", flexDirection:"row", alignItems:'center', justifyContent: 'space-between', padding: '10px 11%', width: '100%',backgroundColor:"#073EB8", zIndex:15}}>
								
								<img src="https://i.imgur.com/9GiXnNt.png" style={{width: '10%'}}/>
								
								<MenuOutlinedIcon onClick={handleShowNav} sx={{fill:"white"}}/>

							</Box>
						)
					}

				{
					showNav && (
					<Box 
					className={isExiting ? 'menu-box-exit' : 'menu-box'}sx={{width: '100%' , backgroundColor: '#474747d8', position: 'absolute', zIndex: 15, padding: '11%'}}>
							<Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>

								<CloseRoundedIcon onClick={handleShowNav} sx={{fill:"white"}}/>

								<Box  style={{display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'left'}}>

									<span
									onClick={() => handleScroll('.drDescription')}
									style={{ cursor: "pointer", fontWeight: 200, color: 'white' }}
									className="basicHover"
									>
									Biografía
									</span>

									<span
									onClick={() => handleScroll('.clinicSection')}
									style={{ cursor: "pointer", fontWeight: 200, color: 'white' }}
									className="basicHover"
									>
									La clínica
									</span>

									<span
									onClick={() => handleScroll('.procedSection')}
									style={{ cursor: "pointer", fontWeight: 200, color: 'white' }}
									className="basicHover"
									>
										Procedimientos
									</span>

									<span
									onClick={() => navigate(`/noticias`)} 
									style={{ cursor: "pointer", fontWeight: 200, color: 'white' }}
									className="basicHover"
									>
									Blog
									</span>

									<span
									onClick={() => handleScroll('.sectionContact')}
									style={{ cursor: "pointer", fontWeight: 200, color: 'white'}}
									className="basicHover"
									>
									Contacto
									</span>

								</Box>
							</Box>
					</Box>
					)
				}

					{
						!isMobile ? (
							<Box> 

							<Box className="navAzul">

								<Box sx={{display: 'flex', alignItems: 'center', gap: '28px'}}>

									<Typography  className="dataNavAzul">  <LocalPhoneOutlinedIcon sx={{fill: 'white'}}/> +54 387 4218604 </Typography>

									<Typography className="dataNavAzul">  <WhatsAppIcon sx={{fill: 'white'}}/> +54 387 4218604 </Typography>

								</Box>

								<Box sx={{display: 'flex', alignItems: 'center', gap: '9px'}}>

									<Typography className="dataNavAzul">  <FacebookOutlinedIcon sx={{fill: 'white'}}/> /clinicabergesi </Typography>

									<Typography className="dataNavAzul">  <InstagramIcon sx={{fill: 'white'}}/> @clinicabergesi </Typography>

								</Box>

							</Box>



							<Box className="navBlanca">


									<Typography className="dataNavBlanca"> Inicio </Typography>
									<Typography 
										onClick={() => handleScroll('.drDescription')}
										className="dataNavBlanca"> 
											Biografía
									</Typography>
									<Typography 
									onClick={() => handleScroll('.clinicSection')}
										className="dataNavBlanca"> 
											La clínica
									</Typography>

									<img src="https://i.imgur.com/5RfptjD.png" alt="LogoClinicaBergesi" style={{width: '400px'}}/>

									<Typography 
										onClick={() => handleScroll('.procedSection')}
										className="dataNavBlanca"> Procedimientos </Typography>
									<Typography  
												onClick={() => navigate(`/noticias`)} 
												className="dataNavBlanca"> 
												Blog
									</Typography>
									<Typography
										onClick={() => handleScroll('.sectionContact')}
										className="dataNavBlanca"> 
										Contacto 
									</Typography>


							</Box>

						</Box>

						) :

						(
							<Box> 

								<Box className="navBlanca">


												<img src="https://i.imgur.com/5RfptjD.png" alt="LogoClinicaBergesi" style={{width: '90%'}}/>


								</Box>

							</Box>
							
							
						)
					}
				


					{/* Banner Inicial */}

					<Box className="BannerInicialFoto" sx={{position: 'relative'}}>
						
						{isMobile ?

						<img src="https://i.imgur.com/mO9W5SK.png" alt="BannerInicial" style={{width: '100%'}}/>

						:

						<img src="https://i.imgur.com/ImSwRML.png" alt="BannerInicial" style={{width: '100%'}}/>

						}

						{ !isMobile && 
							<Box sx={{width: '1px', backgroundColor: '#073EB8', position: 'absolute', left: '18%', top: '65%', height: '3500px'}}/>
						}
					</Box>	


					{showScrollButton && (
                      <IconButton
                      className="scroll-to-top"
                      style={{
                          position: 'fixed',
                          bottom: '15vh',
                          right: '5vw',
                          zIndex: 1000,
                          cursor: 'pointer',
                          backgroundColor: 'rgba(71, 71, 71, 0.418)',  
                      }}
                      onClick={scrollToTop}
                  >
                            <KeyboardArrowUpIcon sx={{fill:"#ffff"}} />
                        </IconButton>
                    )}   


					{/* El Doctor */}

					<Box className="drSection">

						<Box className="fotoDr" sx={{zIndex: '4'}}>

							<img src="https://imgur.com/M5kct1b.png" alt="drGustavo" style={{width: '100%', padding: isMobile ? '30px' : '45px', border: "1px solid #073EB8", background: 'white'}}/>

						</Box>

						<Box className="drDescription">

							<Typography className="drTitle"> Gustavo Bergesi </Typography>

							<Typography className="drItems">

								• Médico cirujano por la universidad Nacional de Túcumán con 40 años de experiencia. <br/>

								• Cirujano especialista en cirugía plástica por la UBA y Universidad Interamericana de Buenos Aires contando con la certificación de especialista de estas dos universidades y del colegio médico de Salta. <br/>

								• Miembro titular de SACPER ( sociedad argentina de cirugía plástica), Sociedad Salteña de Cirugía Plástica, y Sociedad Salteña de Cirugía Plástica. <br />

								• En 2005 fundó su clínica en donde se dedica a la asistencia, docencia e investigación. Desde entonces ha operado a más de 15.000 pacientes.

							</Typography>

							<Box 
								onClick={() => handleShowBiografia()}
								className="biography"> 

								<Typography className="biographyText">

									BIOGRAFÍA

								</Typography>

							</Box>
						
						</Box>

					</Box>

					<Box className="clinicSection" sx={{marginTop: isMobile ? '100px' : '0px'}}> 
					
				
					</Box>

					
					<Box className="clinicSection" sx={{marginTop: isMobile ? '100px' : '0px'}}> 

						<Typography className="procedureTitle" sx={{color: '#073EB8'}}> La clínica </Typography>

						<Box className="clinicPhotos">

						<FlippingCard2 card={1} backgroundColor="#ffff" />
					<FlippingCard2 card={2} backgroundColor="#ffff" />
					<FlippingCard2 card={3} backgroundColor="#ffff" />
						

						</Box>

					</Box>

					{/* Tratamientos */}
					<Box className="procedSection" sx={{marginTop: '200px'}}> 

						<Typography className="procedureTitle"> Procedimientos </Typography>

						<Box className="treatments" sx={{marginTop: '50px'}}>

							<Box className="fourSquares">

								<Box className="smallSquare" sx={{backgroundImage: "url('https://i.imgur.com/mwM8vtf.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

								</Box>

							
										  <FlippingCard procedimiento="Otoplastia" backgroundColor="#073EB8" />
											<FlippingCard procedimiento="Queiloplastia" backgroundColor="#C5AF95" />
						

								<Box className="smallSquare" sx={{background: "url('https://i.imgur.com/vMEuBOG.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

								</Box>

							</Box>

							<Box className="bigSquare"  sx={{background: "url('https://i.imgur.com/M5TcB5J.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

							</Box>

						</Box>
					
					</Box>

					<Box className="treatments">
						
						<Box className="bigSquare"  sx={{background: 'white'}}>

							<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '55px'}}> 

								<Typography className='bigSquareText'>
									Donde <br />
									la medicina <br />
									conoce el arte
								</Typography> 

								<Box onClick={() => handleScroll('.drDescription')} className="biography"> 

									SOBRE NOSOTROS

								</Box>

							</Box>

						</Box>

						<Box className="fourSquares">

							{/* <Box className="smallSquare"
							 onClick={() => handleShowProcedimiento("Lifting Cérvico-Facial")}
							sx={{background: 'white',
								cursor:"pointer"
							}}>
								
								<Typography className="squareText" sx={{color: '#073EB8 !important'}}>

									Lifting Cérvico-Facial

								</Typography>

							</Box> */}

							<FlippingCard procedimiento="Lifting Cérvico-Facial" backgroundColor="#ffff" />

							<Box className="smallSquare" sx={{background: "url('https://i.imgur.com/1Wylsqw.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

							</Box>

							
							<FlippingCard procedimiento="Bichectomía" backgroundColor='#C1CDCB' />

							{/* <Box className="smallSquare" 
								 onClick={() => handleShowProcedimiento("Bichectomía")}
							
							sx={{background: '#C1CDCB',
								cursor:"pointer"
							}}>

								<Typography className="squareText">

									Bichectomía

								</Typography>


							</Box> */}
							<FlippingCard procedimiento="Ginecomastia" backgroundColor='#C5AF95' />

							{/* <Box className="smallSquare" 
							 onClick={() => handleShowProcedimiento("Ginecomastia")}
							
							sx={{background: '#C5AF95',
								cursor:"pointer"
							}}>

								<Typography className="squareText">

									Ginecomastia

								</Typography>

							</Box> */}

						</Box>

					</Box>

					<Box className="treatments">

						<Box className="fourSquares">
								<FlippingCard procedimiento="Mastoplastia" backgroundColor='#ffff' />
								<FlippingCard procedimiento="Abdominoplastia" backgroundColor='#073EB8' />
								<FlippingCard procedimiento="Lipoaspiración" backgroundColor='#C1CDCB' />
								<FlippingCard procedimiento="Dermolipectomía de Muslos y Glúteos" backgroundColor='#C5AF95' />
						</Box>


						<Box className="bigSquare"  sx={{background: "url('https://i.imgur.com/cwNhzDB.png')", backgroundSize: 'cover', backgroundPosition: 'center', order: isMobile && '-1'}}>

						</Box>


					</Box>

					<Box className="treatments">
						
						<Box className="bigSquare"  sx={{background: "url('https://i.imgur.com/G0J8YyO.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

						</Box>

						<Box className="fourSquares">
					
							<Box className="smallSquare" sx={{background: "url('https://i.imgur.com/364qJ8N.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

							</Box>
							
							<FlippingCard procedimiento="Lipoaspiración de Muslos" backgroundColor='#C1CDCB' />



							<FlippingCard procedimiento="Relleno de Glúteos (Lipofilling)" backgroundColor='#C5AF95' />

							<Box className="smallSquare" sx={{background: "url('https://i.imgur.com/SbPdwbv.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}>

							</Box>
						

						</Box>

					</Box>

					<Box className="treatments">

						<Box className="fourSquares">
								<FlippingCard procedimiento="Rellenos" backgroundColor='#ffff' />
								<FlippingCard procedimiento="Toxina Botulínica" backgroundColor='#073EB8' />
								<FlippingCard procedimiento="Mesoterapia" backgroundColor='#C1CDCB' />
								<FlippingCard procedimiento="Hilos" backgroundColor='#C5AF95' />
						</Box>


						<Box className="bigSquare"  sx={{background: "url('https://i.imgur.com/Lo1nLic.png')", backgroundSize: 'cover', backgroundPosition: 'center', order: isMobile && '-1'}}>

						</Box>

					</Box>

				<Typography className="procedureTitle" sx={{marginTop: isMobile ? '100px' : '200px', color: '#5C5C5C'}}> Últimas noticias </Typography>

				<Box sx={{
					margin:"30px auto",
					backgroundColor: "white",
					cursor: "default",
					display: "flex",
					flexDirection: isMobile ? 'column' : 'row',
					alignItems: isMobile && 'center',
					justifyContent: !isMobile && 'center',
					gap: "30px",
					
					// No clickeable directamente
				}}>
					{noticias?.map((v, index) => (
				<Box
				key={index}
				sx={{
				  width: isMobile ? '90%' : "450px",
				  height: "630px",
				  border: "1px solid #073EB8",
				  position: "relative",
				  backgroundColor: "white",
				  cursor: "default",
				  display: "flex",
				  flexDirection: "column",
				  gap: "30px"
				  // No clickeable directamente
				}}
			  >
				<img
				  style={{
					width: "100%",
					height: "350px",
					objectFit: "cover",
					borderRadius: "0px",
				  }}
				  src={v.imageUrl}
				  alt={`img de ${v.titulo}`}
				/>
				<Box
				  sx={{
					display: "flex",
					flexDirection: "column",
					gap: "15px"
				  }}
				>
					<Typography
					sx={{
						fontFamily: "IvyOra",
						gap: "5px",
						display: "flex",
						fontSize: isMobile ? "24px" : "32px",
						lineHeight: '100%',
						fontWeight: "400",
						textAlign: "left",
						alignItems: "center",
						color: "#5C5C5C",
						marginBottom: "15px",
						paddingLeft: "15px",
					}}
					>
					{v.titulo}
					
					</Typography>
						<Typography
							sx={{
								paddingLeft: "15px",
								fontFamily: "Josefin",
								gap: "5px",
								display: "-webkit-box",
								WebkitLineClamp: 3, // Límite de 4 líneas
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
								fontSize: isMobile ? "20px" : "24px",
								fontWeight: "light",
								textAlign: "left",
								alignItems: "left",
								color: "#5C5C5C",
								marginBottom: "30px",
								lineHeight: "100%"
							}}
						>
						{v?.subtitulo
							?.split("\\n") // Divide en párrafos.
							.slice(0, 3) // Toma solo las primeras 4 líneas.
							.join(" ") // Une las líneas seleccionadas en una sola cadena.
							.split(/(\*[^*]*\*|#[^#]*#)/) // Aplica formato.
							.map((text, idx) => {
							if (text.startsWith("*") && text.endsWith("*")) {
								// Texto en negrita.
								return (
								<strong className="strNew" key={idx}>
									{text.slice(1, -1)}
								</strong>
								);
							} else if (text.startsWith("#") && text.endsWith("#")) {
								// Texto en cursiva.
								return (
								<em className="strNew" key={idx}>
									{text.slice(1, -1)}
								</em>
								);
							} else {
								return text;
							}
							})}
						</Typography>

        
							{/* Botón Ver Más */}
							<Box
							sx={{
							
								position: "absolute",
								bottom: "10px",
								left: "10px",
								color: "#5C5C5C",
								fontSize: "24px",
								cursor: "pointer",
								fontFamily: "Josefin",
								fontWeight: "light",
								'&:hover':{

								textDecoration: 'underline',
								color: '#215AA6'

								}
							}}
							onClick={() => navigate(`/noticias/${v._id}`)}
							>
							Leer más ⨁
							</Box>
						</Box>
						</Box>
					))}
					</Box>				
					
					
					<Box className="sectionContact" style={{flexDirection:isMobile?"column":"row"}}>
						<Box className="sectionForm">
							<Box className="aboutContent1">
								
								<Box>

									<Typography className="aboutTitle">

										Contacto

									</Typography>

									<Typography className="contactSubtext"> 

										Dejenos su mensaje, toda consulta es bienvenida 

									</Typography>

								</Box>
								<Dialog 
									open={showClinica} 
									onClose={handleShowClinicaClose}
									PaperProps={{
										sx: {
										borderRadius: "15px", // Bordes redondeados
										overflow: "hidden",
										boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Sombra elegante
										maxWidth: "600px", // Tamaño máximo
										backgroundColor: "#ffffff", 
										}
									}}
									>
									<DialogTitle
										sx={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
										padding: "16px 24px",
										fontFamily: "Josefin",
										fontSize: "19px",
										fontWeight: "500",
										color: "#5c5c5c",
										}}
									>
										
										<IconButton
										onClick={handleShowClinicaClose}
										sx={{
											color: "#5c5c5c",
											"&:hover": {
											color: "#000000",
											},
										}}
										>
										<CloseIcon />
										</IconButton>
									</DialogTitle>
									<DialogContent
										sx={{
										padding: "30px", // Espaciado interno
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										textAlign: "center",
										}}
									>
										<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: "15px",
											justifyContent: "center",
											alignItems: "center",
										}}
										>
										<Typography
											sx={{
											paddingLeft: "15px",
											fontFamily: "Josefin",
											gap: "5px",
											display: "-webkit-box",
											WebkitBoxOrient: "vertical",
											overflow: "hidden",
											textOverflow: "ellipsis",
											fontSize: isMobile ? "12px" : "19px",
											fontWeight: "400",
											textAlign: "left",
											alignItems: "left",
											color: "#5c5c5c",
											marginBottom: "30px",
											}}
										>
											{clinicaSeleccionada?.descripcion
											?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
											.map((text, idx) => {
												if (text === "\n" || text === "\\n") {
												return <br key={idx} />;
												} else if (text.startsWith("*") && text.endsWith("*")) {
												return (
													<strong style={{ color: "#5c5c5c" }} key={idx}>
													{text.slice(1, -1)}
													</strong>
												);
												} else if (text.startsWith("#") && text.endsWith("#")) {
												return (
													<em key={idx}>
													{text.slice(1, -1)}
													</em>
												);
												} else {
												return text;
												}
											})}
										</Typography>
										</Box>
									</DialogContent>
									</Dialog>


									<Dialog 
									PaperProps={{
										sx: {
											borderRadius: "0px", 
											overflow: "hidden",
											boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
											margin: '0px !important',
											maxWidth: '100%',
											width: '100%',
											maxHeight: '100%',
											height: '100%',
											backgroundColor: "white", 
										}
									}}
									open={showBiografia} 
									onClose={handleShowBiografiaClose}
									// TransitionComponent={(props) => (
									// 	<Fade {...props} timeout={1000} unmountOnExit /> // Asegúrate de usar `unmountOnExit`
									// )}
									>

											<DialogTitle
													sx={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														padding: "16px 24px",
														fontFamily: "Josefin",
														fontSize: "19px",
														fontWeight: "500",
														color: "#5c5c5c",
													}}
												>
														Biografía del Dr. Bergesi
													<IconButton
														onClick={handleShowBiografiaClose}
														sx={{
															color: "#5c5c5c",
															"&:hover": {
																color: "#000000",
															},
														}}
													>
														<CloseIcon />
													</IconButton>
												</DialogTitle>
										
										<DialogContent>
											<Box sx={{display:"flex",
												flexDirection:"column",
												justifyContent:"space-around"}}>
											<Typography
												sx={{
													paddingLeft: "0px",													
													fontFamily: "Josefin",
													gap: "5px",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontFamily: 'IvyOra',
													fontSize: isMobile ? "12px" : "16px",
													fontWeight: "400",
													textAlign: "left",
													alignItems: "left",
													color: "#5c5c5c",
													marginBottom: "30px",
												}}
												>
												{biografia
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <><br key={idx} /> </>;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong style={{color:"#5c5c5c"}} key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
												<img
              style={{
                width: '100%',
       			marginBottom:"15px",
                objectFit: "cover",
              }}
              src="https://i.imgur.com/DW5UK1z.png"// Usa directamente `noticias.imageUrl`
              alt={`img de biografia1`} // Usa directamente `noticias.titulo`
            />
												<Typography
												sx={{
													paddingLeft: "0px",													
													fontFamily: "Josefin",
													gap: "5px",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontFamily: 'IvyOra',
													fontSize: isMobile ? "12px" : "16px",
													fontWeight: "400",
													textAlign: "left",
													alignItems: "left",
													color: "#5c5c5c",
													marginBottom: "30px",
												}}
												>
												{biografia2
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <><br key={idx} /> </>;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong style={{color:"#5c5c5c"}} key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
												<img
              style={{
                width: '100%',
       			marginBottom:"15px",
                objectFit: "cover",
              }}
              src="https://i.imgur.com/UbOZCdN.png"// Usa directamente `noticias.imageUrl`
              alt={`img de biografia1`} // Usa directamente `noticias.titulo`
            />
												<Typography
												sx={{
													paddingLeft: "0px",													
													fontFamily: "Josefin",
													gap: "5px",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontFamily: 'IvyOra',
													fontSize: isMobile ? "12px" : "16px",
													fontWeight: "400",
													textAlign: "left",
													alignItems: "left",
													color: "#5c5c5c",
													marginBottom: "30px",
												}}
												>
												{biografia3
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <><br key={idx} /> </>;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong style={{color:"#5c5c5c"}} key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
										

											</Box>
										</DialogContent>
									</Dialog>
								<Dialog
								PaperProps={{
									sx: {
										borderRadius: "15px",
										overflow: "hidden",
										boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", 
										maxWidth: "600px", 
										backgroundColor: "#ffffff", 
									}
								}}
								open={showProcedimiento} onClose={handleShowProcedimientoClose}>
										<DialogTitle sx={{color:"#5c5c5c"}}>{procedimientoSeleccionado?.nombre}</DialogTitle>
										<DialogContent>
											<Box sx={{display:"flex",
												flexDirection:"column",
												justifyContent:"space-around"}}>
											<Typography
												sx={{
													paddingLeft: "15px",
													fontFamily: "Josefin",
													gap: "5px",
													display: "-webkit-box",
												
													WebkitBoxOrient: "vertical",
													overflow: "hidden",
													textOverflow: "ellipsis",
													fontSize: isMobile ? "12px" : "19px",
													fontWeight: "400",
													textAlign: "left",
													alignItems: "left",
													color: "#5c5c5c",
													marginBottom: "30px",
												}}
												>
												{procedimientoSeleccionado?.descripcion
														?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
														.map((text, idx) => {
															if (text === "\n" || text === "\\n") {
															// Agrega salto de línea.
															return <br key={idx} />;
															} else if (text.startsWith("*") && text.endsWith("*")) {
															// Texto en negrita.
															return (
																<strong style={{color:"#5c5c5c"}} key={idx}>
																{text.slice(1, -1)}
																</strong>
															);
															} else if (text.startsWith("#") && text.endsWith("#")) {
															// Texto en cursiva.
															return (
																<em key={idx}>
																{text.slice(1, -1)}
																</em>
															);
															} else {
															// Texto normal.
															return text;
															}
														})}

												</Typography>
									

											</Box>
										</DialogContent>
									</Dialog>



								<Box>
								<form>
								<Stack spacing={5}>
									<TextField 
										label="Nombre Completo"
										name="nombre" 
										value={formData.nombre} 
										onChange={handleChange}
										variant="outlined" 
										fullWidth 
										required 
										sx={{
											backgroundColor: "#ffff",
											borderRadius: '100px',
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#5C5C5C",
												},
												"&:hover fieldset": {
													borderColor: "#073EB8",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#073EB8",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#d9d9d9",
											},
											"& .MuiInputLabel-root.Mui-focused": {
												color: "#073EB8",
											},
											"& .MuiInputLabel-root.MuiFormLabel-filled": {
												color: "#073EB8",
											},
										}}
									/>
									
									<TextField 
										style={{backgroundColor:"#ffff"}}
										label= "Correo Electrónico"  
										name="email"
										value={formData.email}
										onChange={handleChange}
										variant="outlined" 
										fullWidth 
										type="email" 
										required 
										sx={{
											backgroundColor: "#ffff",
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#5C5C5C",
												},
												"&:hover fieldset": {
													borderColor: "#073EB8",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#073EB8",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#d9d9d9",
											},
											"& .MuiInputLabel-root.Mui-focused": {
												color: "#073EB8",
											},
											"& .MuiInputLabel-root.MuiFormLabel-filled": {
												color: "#073EB8",
											},
										}}
									/>
									
									<TextField 
										style={{backgroundColor:"#ffff"}}
										label="Teléfono"
										name="telefono"
										value={formData.telefono}
										onChange={handleChange}
										variant="outlined" 
										fullWidth 
										required 
										sx={{
											backgroundColor: "#ffff",
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#5C5C5C",
												},
												"&:hover fieldset": {
													borderColor: "#073EB8",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#073EB8",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#d9d9d9",
											},
											"& .MuiInputLabel-root.Mui-focused": {
												color: "#073EB8",
											},
											"& .MuiInputLabel-root.MuiFormLabel-filled": {
												color: "#073EB8",
											},
										}}
									/>
									
									<TextField 
										style={{backgroundColor:"#ffff"}}
										label="Mensaje"
										name="mensaje"
										value={formData.mensaje}
										onChange={handleChange}
										variant="outlined" 
										fullWidth 
										multiline 
										rows={4} 
										sx={{
											backgroundColor: "#ffff",
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#5C5C5C",
													fontFamily: 'Josefin'
												},
												"&:hover fieldset": {
													borderColor: "#073EB8",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#073EB8",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#d9d9d9",
											},
											"& .MuiInputLabel-root.Mui-focused": {
												color: "#073EB8",
											},
											"& .MuiInputLabel-root.MuiFormLabel-filled": {
												color: "#073EB8",
											},
										}}
									/>
									
									<Box
										sx={{
											width: isMobile ? "100%" : "100%",
											margin: '5vh auto',
											height: "80px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											backgroundColor: (formData?.nombre && formData?.email && formData?.telefono) ? '#073EB8' : "#d9d9d9",
											transition: 'background-color 0.3s, transform 0.3s',
											"&:hover": {
												backgroundColor: (formData?.nombre && formData?.email && formData?.telefono) ? '#073EB8' : "#d9d9d9",
												transform: (formData?.nombre && formData?.email && formData?.telefono) ? 'scale(1.05)' : 'none',
											},
											pointerEvents: (!formData?.nombre || !formData?.email || !formData?.telefono) ? "none" : "auto"
										}}
										onClick={(e) => (formData?.nombre && formData?.email && formData?.telefono) ? handleSubmit(e) : console.error("Faltan datos")}
									>
										<Typography style={{ color: "#fff" }}>
											ENVIAR MENSAJE
										</Typography>
									</Box>
								</Stack>

								</form>
								</Box>
							</Box>

			
						</Box>

						<Box className="contactSection" >
							
							<Typography className="aboutTitle">

							Donde estamos

							</Typography>

							<a href="https://maps.app.goo.gl/8c7ce6Znw7PWRWgi7" rel='noreferrer' target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
							<Box sx={{width: '100%', display: 'flex', alignItems: 'center', gap: '30px'}}>

								<PlaceOutlinedIcon/>

								<Typography sx={{fontFamily: 'Helvetica', fontSize: '18px', fontWeight: '200', color: '#575756', textAlign: 'left'}}>Alvarado 1434, Salta, Argentina 4400</Typography>
	
							</Box>
							</a>
							
							<Box>

							<div style={{ width: isMobile ? '80%' :'100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.1494811066796!2d-65.42110905999755!3d-24.79033446888068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x941bc3b1eb4da4bb%3A0x19d846ebd59fb0a8!2sAlvarado%201434%2C%20A4400%20Salta!5e0!3m2!1ses!2sar!4v1734488037004!5m2!1ses!2sar"
								width="400"
								height="300"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
								title="Google Map Location"
								></iframe>
							</div>
							</Box>

							<Typography className="aboutTitle">

								Horarios

							</Typography>

							<a href="https://maps.app.goo.gl/8c7ce6Znw7PWRWgi7" rel='noreferrer' target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
							<Box sx={{width: '100%', display: 'flex', alignItems: 'center', gap: '30px'}}>

								<AccessAlarmsOutlinedIcon/>

								<Typography sx={{fontFamily: 'Helvetica', fontSize: '18px', fontWeight: '200', color: '#575756', textAlign: 'left'}}>Lunes a Viernes: 08:30hs a 20:00hs</Typography>

							</Box>
							</a>


						</Box>
					</Box>

				</div>

			)


}
</>
)
}

export default Home;
