export function validateLogin(loginData) {
	let errors = {};

	if (!loginData.name) {
		errors.name = 'Debes ingresar un usuario.';
	}

	if (!loginData.password) {
		errors.password = 'Debes ingresar una contraseña.';
	}

	return errors;
}
