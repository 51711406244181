import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import './FlippingCard2.Module.css'; // Archivo CSS para el efecto de giro
import { useMediaQuery } from 'react-responsive';

const FlippingCard2 = ({ procedimiento, backgroundColor, card }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const procedimientos=[
		{
            card:1,
            imagen:"https://i.imgur.com/AAlyD1w.png",
            gradient:'linear-gradient(180deg, rgba(193, 205, 203, 0) 55%, #C0CCCA 100%)',
			nombre:"Quirófano propio de uso exclusivo para intervenciones estéticas",
			descripcion:"La seguridad es nuestra prioridad, por eso sólo intervenimos en quirófano propio que cumple con todas las normativas y regulaciones médicas de las más altas instituciones nacionales. \n Además, desde el inicio hasta el final, la atención está brindada por personal médico, y toda intervención que requiera de anestesia está supervisada por un médico anestesiólogo."
		},
		{
            card:2,
           imagen: "https://imgur.com/jQJgGN0.png",
           gradient:'linear-gradient(180deg, rgba(197, 175, 149, 0) 55%, #C5AF95 100%)',
			nombre:"Entorno cálido e íntimo",
			descripcion:"Luego de la cirugía, nuestros pacientes disfrutan de una recuperación rápida y cuidada en habitaciones privadas, con atención exclusiva de médicos y enfermeros profesionales. \n Nuestras instalaciones están diseñadas para darte una experiencia relajante, de disfrute y privacidad."

		},{
            card:3,
            imagen:"https://imgur.com/WeEoZvH.png",
            gradient:'linear-gradient(180deg, rgba(7, 62, 184, 0) 55%, #073EB8 100%)',
			nombre:"Investigación, docencia y práctica",
			descripcion:"Es una institución con veinte años de trayectoria en investigación, docencia y práctica quirúrgica que nos permite asegurar intervenciones de alto conocimiento técnico y actualización científica. \n El Dr. Gustavo bergesi se encuentra acompañado de un gran equipo quirúrgico compuesto por Cirujanos ayudantes, anestesistas, enfermeras e instrumentadores quirúrgicos, todos profesionales debidamente certificados. "

		}
	]

  const procedimientoData = procedimientos.find(
    (item) => item.card === card
  );

  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  if (!procedimientoData) {
    return (
      <Box sx={{ padding: 2, backgroundColor: '#f8d7da', borderRadius: '8px' }}>
        <Typography>
          Procedimiento no encontrado: <strong>{procedimiento}</strong>
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      className={`flipping-card2 ${isFlipped ? 'flipped2' : ''}`}
      sx={{
        backgroundColor: isFlipped ? '#f5f5f5' : backgroundColor,
        transition: 'background-color 0.5s ease',
        width: isMobile ? '100%' : '30%',
        position: 'relative',
        height: '630px',
      }}
    >
      {/* Cara frontal */}
      <Box className="front2">
        {/* Imagen como fondo */}
        <img src={procedimientoData.imagen} alt="imagen del procedimiento" />
        
        {/* Gradiente */}
        <Box className="gradient-overlay" sx={{ background: procedimientoData?.gradient }} />
  
        {/* Contenido */}
        <Box className="content">
          <Typography className="clinicaPhotoDesc">{procedimientoData.nombre}</Typography>
          <Box onClick={handleFlip} className="clinicaPhotoButton">
            VER MÁS
          </Box>
        </Box>
      </Box>
  
      {/* Cara trasera */}
      <Box onClick={handleFlip} className="back2" sx={{ padding: '20px' }}>
        <Typography className="textBack2">
          {procedimientoData?.descripcion
            ?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/)
            .map((text, idx) => {
              if (text === '\n' || text === '\\n') {
                return <br key={idx} />;
              } else if (text.startsWith('*') && text.endsWith('*')) {
                return <strong key={idx}>{text.slice(1, -1)}</strong>;
              } else if (text.startsWith('#') && text.endsWith('#')) {
                return <em key={idx}>{text.slice(1, -1)}</em>;
              } else {
                return text;
              }
            })}
        </Typography>
      </Box>
    </Box>
  );
};

export default FlippingCard2;