import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import './FlippingCard.Module.css'; // Archivo CSS para el efecto de giro

const FlippingCard = ({ procedimiento, backgroundColor }) => {

  const procedimientos=[
		{
			nombre:"Otoplastia",
			descripcion:"*Armoniza* la forma y posición de nuestras orejas con el resto del rostro. \n Es un procedimiento delicado que moldea y reposiciona las orejas prominentes."
		},
		{
			nombre:"Queiloplastia",
			descripcion:"Redefine y rejuvenece el contorno labial, manteniendo la armonía y expresión natural del rostro, sin alterar la esencia de nuestra sonrisa."

		},{
			nombre:"Lifting Cérvico-Facial",
			descripcion:"Revitaliza  nuestro rostro y cuello, tensando suavemente la piel y los tejidos subyacentes. \n Este procedimiento resalta la estructura facial, eliminando los signos del efecto de la gravedad mientras conserva la expresión natural y la vitalidad de cada individuo."

		},{
			nombre:"Bichectomía",
			descripcion:"Es una técnica quirúrgica que reduce el volumen de las mejillas para esculpir y definir los contornos faciales. \n  Se destacan los pómulos, aportando una apariencia más estilizada sin perder la expresión natural del rostro."

		},{
			nombre:"Ginecomastia",
			descripcion:"Procedimiento quirúrgico que aborda el desarrollo excesivo de tejido mamario en hombres, restaurando un contorno torácico más firme y masculino. \n La masculinización del tórax mejora la confianza y la estética corporal sin comprometer la naturalidad del resultado."
		},

		{
			nombre:"Mastoplastia",
			descripcion:"*De aumento*: Armoniza la figura femenina mediante la inserción de implantes de silicona homologados de alta tecnología. \n  Estos proporcionan volumen y forma al busto, respetando la proporción y estética natural de cada cuerpo. \n  Tanto el tamaño como su morfología son elegidos por la paciente mediante pruebas. \n *Reducción*: Alivia las molestias causadas por un busto voluminoso, equilibrando la figura y mejorando el confort físico y emocional. \n *Levantamiento*: Restaura el busto que descendió por efecto de la gravedad devolviéndolo a su posición y firmeza, sin alterar la forma natural. \n *Reparadora*: corrige asimetrías y deformidades del busto, devolviendo armonía y confianza a la persona, preservando siempre su identidad física."
		}
		,{
			nombre:"Abdominoplastia",
			descripcion:"Redefine el contorno abdominal al eliminar piel, grasa, y tensar los músculos subyacentes. \n  Este procedimiento crea un perfil más liso y firme, resaltando la silueta natural y mejorando la autopercepción."
		}
		,{
			nombre:"Lipoaspiración",
			descripcion:"Esculpe y moldea el cuerpo mediante la eliminación de depósitos de grasa localizada. \n Esta técnica delicada acentúa las líneas naturales del cuerpo, proporcionando una figura más estilizada sin perder la armonía corporal."
		},
		{
			nombre:"Dermolipectomía de Muslos y Glúteos",
			descripcion:"Elimina el exceso de piel y grasa de los muslos y glúteos, restaurando una apariencia firme y tonificada. \n  Este procedimiento define la silueta corporal, manteniendo la naturalidad de las formas."
		},
		{
			nombre:"Lipoaspiración de Muslos",
			descripcion:"Esculpe y moldea el cuerpo mediante la eliminación de depósitos de grasa localizada. \n Esta técnica delicada acentúa las líneas naturales del cuerpo, proporcionando una figura más estilizada sin perder la armonía corporal."
		},
		{
			nombre:"Relleno de Glúteos (Lipofilling)",
			descripcion:"Utiliza la propia grasa del paciente para aumentar y esculpir los glúteos, creando una forma más voluptuosa y natural."
		},
		{
			nombre:"Rellenos",
			descripcion:"Los rellenos dérmicos son la solución ideal para líneas, arrugas, pliegues, surcos y flacidez. \n Utilizamos solo materiales de alta tecnología  y de seguridad comprobada que renueven la turgencia de la piel y la vitalidad de las zonas tratadas."
		},
		{
			nombre:"Toxina Botulínica",
			descripcion:"La toxina botulínica relaja los músculos faciales, reduciendo líneas de expresión y creando un semblante fresco y descansado."
		},
		{
			nombre:"Mesoterapia",
			descripcion:"La mesoterapia revitaliza la piel mediante microinyecciones de vitaminas y nutrientes, mejorando su apariencia y luminosidad."
		},
		{
			nombre:"Hilos",
			descripcion:"Los hilos tensores proporcionan un efecto lifting no quirúrgico, tensando y rejuveneciendo la piel de manera natural y duradera."
		}
	]

  const procedimientoData = procedimientos.find(
    (item) => item.nombre === procedimiento
  );

  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  if (!procedimientoData) {
    return (
      <Box sx={{ padding: 2, backgroundColor: '#f8d7da', borderRadius: '8px' }}>
        <Typography>
          Procedimiento no encontrado: <strong>{procedimiento}</strong>
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      onClick={handleFlip}
      className={`smallSquare flipping-card ${isFlipped ? 'flipped' : ''}`}
      sx={{ backgroundColor: isFlipped ? '#f5f5f5' : backgroundColor, transition: 'background-color 0.5s ease', }}
    >
      {/* Cara frontal */}
      <Box
  className="front"
  sx={{
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '16px', // Espaciado interno opcional
    height: '100%', // Asegura que el contenido ocupe todo el alto de la caja
  }}
>
      <Typography 
    sx={{ color: backgroundColor === '#ffff' ? '#073EB8' : '#ffffff' }} 
    className="squareText"
  >
    {procedimientoData.nombre}
  </Typography>
      </Box>

      {/* Cara trasera */}
      <Box  className="back"  sx={{ backgroundColor: '#f5f5f5', color: '#333' }}>



        {/* <Typography className={procedimientoData.nombre === "Mastoplastia" ? "backMasto" :"textBack" }>{procedimientoData.descripcion}</Typography> */}
      
    
            <Typography  className={procedimientoData.nombre === "Mastoplastia" ? "backMasto" :"textBack" }
                          
                            >
                            {procedimientoData?.descripcion
                                ?.split(/(\n|\\n|[*][^*]*[*]|#[^#]*#)/) // Divide en saltos de línea y formatos.
                                .map((text, idx) => {
                                  if (text === "\n" || text === "\\n") {
                                  // Agrega salto de línea.
                                  return <br key={idx} />;
                                  } else if (text.startsWith("*") && text.endsWith("*")) {
                                  // Texto en negrita.
                                  return (
                                    <strong key={idx}>
                                    {text.slice(1, -1)}
                                    </strong>
                                  );
                                  } else if (text.startsWith("#") && text.endsWith("#")) {
                                  // Texto en cursiva.
                                  return (
                                    <em key={idx}>
                                    {text.slice(1, -1)}
                                    </em>
                                  );
                                  } else {
                                  // Texto normal.
                                  return text;
                                  }
                                })}
    
                            </Typography>
      
      
      
      </Box>
    </Box>
  );
};

export default FlippingCard;